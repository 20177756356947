@import "styles/mixins";
@import "styles/variables";


.root {
    :global {
        position: relative;
        background: $secondary-color;
        overflow: hidden;

        h4,
        .subtitle {
            color: $brown-color;
        }

        .subtitle {
            font-size: 21px;
            font-weight: 500;
            line-height: 28.35px;
        }

        .floating-icon {
            position: absolute;
            opacity: 0.6;

            svg {
                path {
                    fill: $brown-color;
                }
            }

            &:nth-child(1) {
                left: -120px;
                top: 110px;
            }

            &:nth-child(2) {
                right: -200px;
                top: 0;
            }

            &:nth-child(3) {
                right: -180px;
                bottom: 0;
            }

            &:nth-child(4) {
                right: -100px;
                top: 45%;
                opacity: 1;
            }

            &:nth-child(5) {
                left: -150px;
                bottom: 50px;
                opacity: 1;
            }
        }

        .accordion {
            gap: 30px;
            display: flex;
            flex-wrap: wrap;
        }

        .accordion-item {
            border: none;
            border-radius: 20px;
            width: 100%;
            box-shadow: 0px 7px 9px 0px rgba(118, 118, 118, 0.25);

        }

        .accordion-body {
            padding: 0px 30px 30px 30px;
            font-size: 21px;
            line-height: 28.35px;
            color: $font-color;

            p {
                font-size: 21px;
                line-height: 28.35px;
                color: $font-color;
            }
        }

        .accordion-header {
            .accordion-button {
                border: none;
                background: transparent;
                color: $primary-color;
                font-weight: 700;
                font-size: 21px;
                padding: 30px;
                line-height: 28.35px;
                box-shadow: none;
                outline: none;
                gap: 12px;


                &:after {
                    content: "";
                    background-image: url("/site-assets/svg/faq-arrow.svg");
                    background-size: contain;
                    background-repeat: no-repeat;
                    border: none;
                    width: 25.6px;
                    height: 25.6px;
                    @include transform(rotate(0deg));

                }

                &.collapsed {
                    &:after {
                        @include transform(rotate(180deg));

                    }
                }
            }
        }

        @media (min-width: $breakpoint-lg) {
            .container {
                max-width: 920px;
            }
        }

    }

    &:global.jobs-by-location:before {
        content: "";
        position: absolute;
        height: 100%;
        bottom: -35%;
        left: 0;
        right: 0;
        background-image: url('data:image/svg+xml,<svg width="1440" height="501" viewBox="0 0 1440 501" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="1440" height="501" transform="matrix(-1 0 0 1 1440 0)" fill="%23005CA9"/><path d="M3.81959e-05 -0.00012207L-1.2732e-05 122.896C566.393 44.2598 1000.2 -68.9999 1440 79.5L1440 -0.000247959L3.81959e-05 -0.00012207Z" fill="%23F4F4F4"/></svg>');
        // background-size: auto 100%;
        background-size: cover;
        background-repeat: no-repeat;
        // background-position: center bottom;
        background-position: center top;
    }

    &:global.jobs-by-location {
        background: rgba(244, 244, 244, 1);
        padding-bottom: 3rem;

        .floating-icon {
            svg {
                path {
                    fill: $base-white;
                }
            }

            &:nth-child(1),
            &:nth-child(2) {
                svg {
                    path {
                        fill: $secondary-color;
                    }
                }
            }
        }

        @media (min-width: $breakpoint-lg) {
            padding-bottom: 6rem;
        }

    }


    &:global.teaching-jobs {
        .floating-icon {
            svg {
                path {
                    fill: $base-white;
                }
            }
        }
    }

    &:global.teaching-jobs:before {
        content: "";
        position: absolute;
        height: 100%;
        bottom: -35%;
        left: 0;
        right: 0;
        background-image: url('data:image/svg+xml,<svg width="1440" height="501" viewBox="0 0 1440 501" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="1440" height="501" transform="matrix(-1 0 0 1 1440 0)" fill="%23005CA9"/><path d="M3.81959e-05 -0.00012207L-1.2732e-05 122.896C566.393 44.2598 1000.2 -68.9999 1440 79.5L1440 -0.000247959L3.81959e-05 -0.00012207Z" fill="%23FFB81C"/></svg>');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center top;
    }

    &:global.cpd {
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: center bottom;
        background-image: url('data:image/svg+xml,<svg width="1440" height="145" viewBox="0 0 1440 145" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="1440" height="145" transform="matrix(-1 0 0 -1 1440 145)" fill="%23FFB81C"/><path d="M3.81959e-05 145L-1.2732e-05 22.1033C566.393 100.74 1000.2 214 1440 65.4998L1440 145L3.81959e-05 145Z" fill="%23F4F4F4"/></svg>');
        padding-bottom: 10rem;
    }
}