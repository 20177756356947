@import "styles/mixins";
@import "styles/variables";


.root {
    :global {
        border-radius: 20px;
        // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        margin: 0 0 0 10px;
        height: 100%;
        position: relative;
        z-index: 1;
        // border: none;

        .item {
            padding: 30px;
            height: 100%;
            background: $base-white;
            border-radius: 20px;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);


            >div {
                top: 0;
                position: relative;
                @include trans;
            }

            &:hover,
            &:focus,
            &:active {
                >div {
                    top: -10px;
                }
            }

        }

        .post-title {
            color: $primary-color;
            @include line-clamp(21px, 1.3, 2);
        }

        .post-details-wrapper {
            >div {
                width: 100%;
            }
        }

        .post-details {
            position: relative;
            padding-left: 1.5rem;

            &:before {
                content: "";
                position: absolute;
                background-repeat: no-repeat;
                background-size: contain;
                left: 0;
                top: 5px;
                width: 18px;
                height: 18px;

            }

            &.post-location {
                &:before {
                    background-image: url("/site-assets/svg/job-location-icon.svg");

                }
            }

            &.post-job-sector {
                padding: 0;
                display: flex;
                gap: 5px;
                flex-wrap: wrap;

                span {
                    background: rgba(63, 165, 53, 0.5);
                    line-height: 16.2px;
                    display: inline-block;
                    color: rgba(0, 0, 0, 0.7);
                    font-size: 12px;
                    border-radius: 10px;
                    padding: 6px;
                }
            }

            &.post-job-type {
                &:before {
                    background-image: url("/site-assets/svg/job-type-icon.svg");

                }
            }

            &.post-job-salary {
                &:before {
                    background-image: url("/site-assets/svg/job-salary-icon.svg");

                }
            }

            &.post-job-expiry {
                // padding-top: 0.3rem;
                margin-top: 0.3rem;
                font-size: 12px;
                line-height: 15.6px;

                &:before {
                    top: 0;
                    background-image: url("/site-assets/svg/job-clock-icon.svg");

                }
            }
        }
    }

    &:before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 10px;
        bottom: 0;
        height: 101%;
        width: 99%;
        left: -10px;
        border-radius: 20px;
        right: 0;
        // background: rgba(185, 185, 185, 1);
        background: rgba(63, 165, 53, 1);


    }

    &:global.primary:before {
        background: $fifth-color;
    }

    &:global.primary {
        .post-details {
            &.post-job-sector {
                span {
                    background: rgba(245, 76, 106, 0.5);
                }
            }
        }
    }

    &:global.ect:before {
        background: #42E88E;
    }

    &:global.ect {
        .post-details {
            &.post-job-sector {
                span {
                    background: rgba(66, 232, 142, 0.5);
                }
            }
        }
    }

    &:global.school-support-jobs:before {
        background: #0076D9;
    }

    &:global.school-support-jobs {
        .post-details {
            &.post-job-sector {
                span {
                    background: rgba(0, 92, 142, 0.5);
                }
            }
        }
    }

    &:global.secondary:before {
        background: #4CC9F0;
    }

    &:global.secondary {
        .post-details {
            &.post-job-sector {
                span {
                    background: rgba(76, 201, 240, 0.5);
                }
            }
        }
    }

    &:global.sen:before {
        background: #8376D3;

    }

    &:global.sen {
        .post-details {
            &.post-job-sector {
                span {
                    background: rgba(131, 118, 211, 0.5);
                }
            }
        }
    }


    &:global.teaching-assistant:before {
        background: #FFB81C;

    }

    &:global.teaching-assistant {
        .post-details {
            &.post-job-sector {
                span {
                    background: rgba(255, 184, 28, 0.5);
                }
            }
        }
    }

}