@import "styles/mixins";
@import "styles/variables";


.root {
    :global {
        // padding-top: 2%;
        // position: relative;
        // background-position: center bottom;
        // background-repeat: no-repeat;
        // background-size: 100% auto;
        // background-image: url('data:image/svg+xml,<svg width="1440" height="450" viewBox="0 0 1440 450" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="1440" height="449" transform="matrix(-1 0 0 1 1440 0.859375)" fill="%23005CA9"/><path d="M-1.82156e-05 0.859619L0 354.858C351.512 347.657 1207.09 -105.939 1440 188.359L1440 0.859493L-1.82156e-05 0.859619Z" fill="%23F4F4F4"/></svg>');
        padding-bottom: 8%;
        padding-top: 5%;
        height: 100%;
        background-position: center top;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url('data:image/svg+xml,<svg width="1440" height="449" viewBox="0 0 1440 449" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="1440" height="449" transform="matrix(-1 0 0 1 1440 0)" fill="%23005CA9"/><path d="M-1.82156e-05 0.00012207L0 353.999C351.512 346.797 1207.09 -106.799 1440 187.5L1440 -3.81849e-06L-1.82156e-05 0.00012207Z" fill="%23F4F4F4"/></svg>');

        .overlay-img {
            position: absolute;
            bottom: -1px;
            left: 0;
            right: 0;
        }

        .wrapper {
            background: $secondary-color;
            max-width: 988px;
            margin: 0 auto;
            border-radius: 30px;
            padding: 3rem;
            gap: 30px;
            @include flex;

            .inner-wrapper {
                >div {
                    &:nth-child(2) {
                        min-width: 150px;
                    }
                }
            }

        }

        p {
            font-size: 21px;
            line-height: 28.35px;
            color: $brown-color;
        }

        .h3 {
            span {
                color: $primary-color;
            }
        }

        img {
            min-height: 267px;
            position: absolute;
            bottom: -70px;
            right: -40px;
            @include object-fit(contain);
        }



        @media (min-width: $breakpoint-lg) {
            .wrapper {
                .inner-wrapper {
                    @include flex-wrap(nowrap);

                    >div {
                        &:nth-child(2) {
                            min-width: 270px;
                        }
                    }
                }

            }

            img {
                min-height: 486px;
            }

        }

        @media (max-width: $breakpoint-lg) {
            img {
                max-width: 150px;
                top: -130px;
                bottom: auto;
                right: 0;
            }

            .wrapper {
                padding: 1.5rem;
                gap: 0;

                >div {
                    &:nth-child(1) {
                        >div {
                            width: 100%;

                            h2 {
                                max-width: 160px;
                            }
                        }
                    }

                    &:not(:last-child) {
                        width: 100%;
                    }


                }
            }
        }
    }

    // &:global:after {

    //     content: "";
    //     display: block;
    //     height: 100px;
    //     background: $primary-color;


    // }
}