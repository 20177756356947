@import "styles/mixins";
@import "styles/variables";


.root {
    :global {
        background: rgba(244, 244, 244, 1);

        .focused-text {
            p {
                font-size: 21px;
                line-height: 28.35px;

                b {
                    font-size: 16px;
                }
            }
        }

        .wrapper {
            @include flex;
            @include flex-wrap(wrap);
            gap: 30px;
        }

        .column-wrapper {
            gap: 10px;

            .column {
                width: 100%;

                >div {
                    border-radius: 30px;
                    padding: 5px 10px;
                    display: inline-block;
                    border: 1px solid rgba(185, 185, 185, 1);

                    svg {
                        margin-top: 2px;
                        width: 23px;
                        height: 23px;

                        path {
                            fill: $primary-color;
                        }
                    }
                }
            }
        }

        .content {
            ul {
                padding: 0;
                @include flex;
                @include flex-wrap(wrap);
                gap: 10px;

                li {
                    width: 100%;
                    border-radius: 30px;
                    padding: 5px 10px;
                    display: flex;
                    align-items: flex-start;
                    gap: 10px;
                    border: 1px solid rgba(185, 185, 185, 1);

                    &:before {
                        content: "";
                        display: flex;
                        margin-top: 2px;
                        min-width: 23px;
                        width: 23px;
                        height: 23px;
                        background-repeat: no-repeat;
                        background-image: url('data:image/svg+xml,<svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M23.9168 11.5L21.2735 8.48831L21.6418 4.50164L17.731 3.6133L15.6835 0.168304L12.0002 1.74997L8.31683 0.168304L6.26933 3.6133L2.3585 4.4908L2.72683 8.47747L0.0834961 11.5L2.72683 14.5116L2.3585 18.5091L6.26933 19.3975L8.31683 22.8425L12.0002 21.25L15.6835 22.8316L17.731 19.3866L21.6418 18.4983L21.2735 14.5116L23.9168 11.5ZM9.8335 16.9166L5.50016 12.5833L7.02766 11.0558L9.8335 13.8508L16.9727 6.71164L18.5002 8.24997L9.8335 16.9166Z" fill="%238376D3"/></svg>');
                    }
                }
            }
        }

        @media (min-width: $breakpoint-lg) {
            .wrapper {
                gap: 150px;
                @include flex-wrap(nowrap);

                >div {
                    &:nth-child(1) {
                        max-width: 383px;
                        min-width: 383px;
                    }
                }
            }

            .column-wrapper {
                .column {
                    width: calc((100%/2) - 5px);
                }
            }

            .content {
                ul {

                    li {
                        width: auto;

                    }
                }
            }
        }
    }

    &:global.style-1 {
        @media (min-width: $breakpoint-xxl) {
            .wrapper {
                >div {
                    &:nth-child(1) {
                        min-width: 580px;
                        max-width: 580px;
                    }
                }
            }
        }
    }

    &:global.purple-label {
        .column-wrapper {
            .column {
                >div {
                    svg {
                        path {
                            fill: rgba(131, 118, 211, 1);
                        }
                    }
                }
            }
        }
    }

    &:global.teaching-jobs {
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url('data:image/svg+xml,<svg width="1440" height="145" viewBox="0 0 1440 145" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="1440" height="145" transform="matrix(-1 0 0 1 1440 0)" fill="white"/><path d="M-0.000428515 0.00012207L-0.000205945 79.5002C462 18.1035 766.5 -34.5 1440 79.5002L1440 -3.81853e-06L-0.000428515 0.00012207Z" fill="%23F4F4F4"/></svg>');
        padding-bottom: 6rem;

        @media (min-width: $breakpoint-xxl) {
            padding-bottom: 3rem;
        }
    }
}