@import "styles/mixins";
@import "styles/variables";

.root {
    :global {
        background: $primary-color;
        color: $base-white;
        overflow: hidden;

        .swiper-pagination {
            margin: 3rem 0 0 0;
            right: auto;
            position: relative;
            width: auto !important;
            left: 21% !important;
            @include transform(translateX(-21%));

            span {
                display: block;
                width: 17px;
                height: 17px;

                &.swiper-pagination-bullet {
                    background: rgba($base-black, 0.5);
                    color: transparent;
                }
            }
        }

        .swiper-prev {
            @include transform(rotate(-180deg));
            transition: all 0.3s ease;
        }

        button {
            @include trans;

            svg {
                path {
                    stroke: $base-white;
                }
            }

            &:hover,
            &:focus,
            &:active {
                opacity: 0.5;
            }
        }

        .swiper-slide {
            height: auto;
        }

        .swiper-container {
            margin-top: 2rem;
            padding-bottom: 3rem;
            width: 180vw;
        }

        .swiper {
            padding: 0 0.5rem 1rem 0.5rem;
            margin: 0 -0.5rem;
        }

        .heading,
        .subtitle {
            text-align: center;
        }



        @media (min-width: $breakpoint-lg) {

            .heading,
            .subtitle {
                text-align: left;
            }

            .swiper-container {
                width: 120vw;
            }
        }

        @media (min-width: $breakpoint-xxxl) {
            .swiper-container {
                width: 110vw;
            }
        }
    }

    &:global.homepage:after {
        content: "";
        background-image: url('data:image/svg+xml,<svg width="1440" height="179" viewBox="0 0 1440 179" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="1440" height="179" transform="matrix(-1 0 0 1 1440 0)" fill="white"/><path d="M3.81959e-05 0.00012207L-1.2732e-05 122.897C566.393 44.26 1000.2 -68.9996 1440 79.5002L1440 -3.81849e-06L3.81959e-05 0.00012207Z" fill="%23005CA9"/></svg>');
        background-repeat: no-repeat;
        background-size: 101% auto;
        background-position: center bottom;
        width: 100%;
        padding-bottom: 13%;
        display: block;
        position: relative;
    }

    &:global.homepage {
        padding-top: 3rem;
    }

    &:global.cpd {
        background: rgba(244, 244, 244, 1);
        padding: 3rem 0 0 0;
        color: $font-color;

        .swiper-container {
            padding-bottom: 0;
        }

        .card {
            box-shadow: 0px 7px 9px 0px rgba(118, 118, 118, 0.25);
        }

        button {

            svg {
                path {
                    stroke: $base-black;
                }
            }
        }
    }

    &:global.about-tradewind {
        padding-top: 3rem;
        background: transparent;
        color: $font-color;
        margin-top: -1px;

        .card {
            box-shadow: 0px 7px 9px 0px rgba(118, 118, 118, 0.25);

        }

        button {

            svg {
                path {
                    stroke: $base-black;
                }
            }
        }

    }
}