// Fonts
$font-family: "Poppins", sans-serif;

// Colours
$primary-color: #005CA9;
$secondary-color: #FFB81C;
$tertiary-color: #93e18cd9;
$quarternary-color: #e9e9e9;
$fifth-color: #F54C6A;
$danger-color: #DC5068;

$bg-light: #e7f6fc;
$bg-dark: #1d1d1d;
$bg-color: #ffffff;

$base-white: #ffffff;
$base-black: #000000;
$font-color: #121202;

$brown-color: #4B3505;

// Links
$link-color: $primary-color;
$link-color-hover: darken($link-color, 5%);
$link-decoration: none;
$link-decoration-hover: none;

// Social
$linkedin: #0a66c2;
$facebook: #3b5998;
$twitter: #1da1f2;
$youtube: #ff0000;
$instagram: #c32aa3;
$whatsapp: #25d366;

// Breakpoints
$breakpoint-xlg: 1929px;
$breakpoint-xxxl: 1599px;
$breakpoint-xxl: 1399px;
$breakpoint-xl: 1199px;
$breakpoint-lg: 991px;
$breakpoint-md: 767px;
$breakpoint-sm: 566px;