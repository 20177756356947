@import "styles/mixins";
@import "styles/variables";


.root {
    :global {
        overflow: hidden;
        padding: 3rem 0;
        color: $brown-color;

        .label-text {
            background: #193463;
            display: inline-block;
            color: $base-white;
            border-radius: 10px;
            padding: 10px;
            @include transform(rotate(-3.18deg));

        }

        .label-img {
            top: -20px;
            left: -60px;
            max-width: 150px;
            border-radius: 10px;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

        }

        .corner-subtitle-text {
            right: 0;
            top: 5px;
            z-index: 3;
            color: $base-white;
            text-align: center;
            font-weight: 600;
            font-size: 32px;
            line-height: 30px;
            @include transform(rotate(23deg));
        }

        .h3 {
            color: $brown-color;
        }

        .secondaryBtn {
            word-break: break-all;
        }

        .overlay-dots {
            top: 10px;
            right: -60px;

            svg {
                g {
                    opacity: 0.2;
                }
            }
        }

        .floating-icons {
            position: absolute;
            z-index: 1;

            svg {
                path {
                    fill: $base-white;
                }
            }

            &:nth-of-type(1) {
                right: 50px;
                top: 0px;
                @include transform(scale(1.5));
            }

            &:nth-of-type(2) {
                left: 30px;
                top: 30px;
                @include transform(scale(1.4) rotate(-30deg));
            }

            &:nth-of-type(3) {
                display: none;
                left: 270px;
                bottom: 70px;
                @include transform(scale(1.4) rotate(-30deg));
            }
        }

        img {
            width: 100%;
            max-width: 294px;
            max-height: 294px;
            object-fit: cover;

            &.secondary-img {
                max-width: 170px;
                width: 100%;
                right: -40px;
                top: -50px;
                z-index: 2;

                @media (max-width: $breakpoint-lg) {
                    top: auto;
                    bottom: -15px;

                }
            }
        }

        .wrapper {
            background: $secondary-color;
            padding: 3rem;
            border-radius: 30px;
            position: relative;
            gap: 30px;
            @include flex;
            @include flex-wrap(wrap);
            @include transform(rotate(1deg));

            >div {
                text-align: center;
                width: 100%;
                @include transform(rotate(-1deg));
            }

        }

        @media (min-width: $breakpoint-lg) {
            padding: 6rem 0 3rem 0;

            .wrapper {
                gap: 150px;
                @include flex-wrap(nowrap);

                >div {
                    text-align: left;

                    &:nth-child(1) {
                        max-width: 300px;
                        min-width: 300px;
                    }
                }
            }

            .floating-icons {

                &:nth-of-type(3) {
                    display: block;
                }
            }


        }

        @media (max-width: $breakpoint-lg) {
            .wrapper {
                padding: 2rem 1rem;
            }

            .left {
                max-width: 350px;
                margin: 0 auto;
            }

            .corner-subtitle-text {
                top: 230px;
                right: 30px;
                font-size: 21px;
                line-height: 30px;
            }

            img {
                &.secondary-img {
                    max-width: 140px;
                    right: -10px;

                }
            }

            .label-img {
                left: auto;
                right: 15px;
                top: auto;
                bottom: 0;
                max-width: 110px;
            }
        }
    }

    &:global.cpd-page.enlarged-img {
        @media (max-width: $breakpoint-lg) {
            .wrapper {
                img {
                    transform: scale(1.3);
                    margin-bottom: 1.5rem;
                }
            }
        }
    }

    &:global.bg-wave-1 {
        background-image: url("/site-assets/svg/background-image-1.svg");
        background-size: cover;
        background-repeat: no-repeat;
    }

    &:global.bg-green {
        .wrapper {
            background: rgba(66, 232, 142, 1);
        }

        h3 {
            color: $font-color;
        }

        p {
            color: rgba(18, 18, 2, 1);
        }
    }

    &:global.bg-blue {
        .wrapper {
            background: rgba(76, 201, 240, 1);

        }
    }

    &:global.style-1 {
        img {
            max-height: 100%;

        }

        .h3 {
            font-size: 36px;
            line-height: 48.6px;
        }


        .floating-icons {
            display: none;

            &:nth-child(1) {
                top: 80px;
                display: block;
            }
        }

        @media (min-width: $breakpoint-lg) {
            .wrapper {
                >div {
                    &:nth-child(2) {
                        padding: 3rem 0;
                    }
                }
            }

            img {
                &.featured-img {
                    @include transform(scale(1.6));

                }
            }
        }
    }

    &:global.jobs-by-location {
        position: relative;
        background-image: url('data:image/svg+xml,<svg width="1440" height="724" viewBox="0 0 1440 724" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="1440" height="723" transform="translate(0 0.933594)" fill="%23005CA9"/><path d="M-3.09477e-05 723.933L-1.27321e-05 369.935C371 474.606 1231.5 786.303 1440 369.934L1440 723.934L-3.09477e-05 723.933Z" fill="%23F4F4F4"/></svg>');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center 120%;

        &-1 {
            background-image: url('data:image/svg+xml,<svg width="1440" height="726" viewBox="0 0 1440 726" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="1440" height="725" transform="translate(0 0.466675)" fill="white"/><path d="M-3.09477e-05 0.466797L-1.27321e-05 354.466C371 249.794 1231.5 -61.9027 1440 354.467L1440 0.466671L-3.09477e-05 0.466797Z" fill="%23F4F4F4"/></svg>');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center top;

            .wrapper {
                transform: rotate(-1deg);

                >div {
                    transform: rotate(1deg);
                }
            }

            .floating-icons {
                svg {
                    path {
                        fill: $secondary-color;
                    }
                }
            }

            @media (min-width: $breakpoint-lg) {
                img {
                    &.featured-img {
                        top: 50px;
                    }
                }
            }
        }

        &-2 {

            .floating-icons {
                &:nth-child(1) {
                    top: 0;
                }

                svg {
                    path {
                        fill: $secondary-color;
                    }
                }
            }

            @media (min-width: $breakpoint-lg) {
                img {
                    &.featured-img {
                        top: 50px;
                    }
                }
            }

        }

        .wrapper {
            transform: rotate(-1deg);

            >div {
                transform: rotate(1deg);
            }
        }

        &.flip-column {
            .overlay-dots {
                left: -20px;
            }
        }

        .floating-icons {
            svg {
                path {
                    fill: $secondary-color;
                }
            }
        }

        @media (min-width: $breakpoint-lg) {
            img {
                &.featured-img {
                    top: 50px;
                    right: -50px;
                }
            }
        }

    }

    &:global.homepage {
        background: $primary-color;

        .wrapper {
            transform: rotate(-1deg);

            >div {
                transform: rotate(1deg);
            }
        }

        .floating-icons {
            svg {
                path {
                    fill: $secondary-color;
                }
            }
        }
    }

    &:global.bg-sky-blue {
        .wrapper {
            background: rgba(76, 201, 240, 1);
        }

        .h3 {
            color: $font-color;
        }

        p {
            color: $font-color;
        }
    }

    &:global.bg-red {
        .wrapper {
            background: rgba(245, 76, 106, 1);

        }

        .h3 {
            color: $font-color;
        }

        p {
            color: $font-color;
        }
    }

    &:global.flip-column {
        .wrapper {
            @include flex-direction(row-reverse);
        }

        .overlay-dots {
            left: -90px;
            right: auto;
            @include transform(scale(0.9));
        }

        .floating-icons {
            &:nth-child(1) {
                right: auto;
                top: 10px;
                left: 60px;
            }
        }

        @media (min-width: $breakpoint-lg) {
            .wrapper {
                padding-left: 6rem;
            }
        }
    }

    &:global.cpd {
        padding-bottom: 5rem;
        background: $secondary-color;
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: center top;
        background-image: url('data:image/svg+xml,<svg width="1440" height="711" viewBox="0 0 1440 711" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="1440" height="711" fill="%23FFB81C"/><path d="M-3.09477e-05 0.00012207L-1.27321e-05 353.999C371 249.327 1231.5 -62.3694 1440 354L1440 -3.81849e-06L-3.09477e-05 0.00012207Z" fill="%23005CA9"/></svg>');

        .wrapper {
            transform: rotate(-1deg);

            >div {
                transform: rotate(1deg);
            }
        }

        .floating-icons {
            svg {
                path {
                    fill: $secondary-color;
                }
            }
        }

        @media (min-width: $breakpoint-lg) {
            padding-bottom: 8rem;
        }
    }

    &:global.teaching-jobs {
        &-1 {
            padding-top: 20%;
            background: $primary-color;
            background-image: url('data:image/svg+xml,<svg width="1440" height="105" viewBox="0 0 1440 105" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M-9.17938e-06 0.0001297L-1.2732e-05 105C371 73.9531 1231.5 -18.4994 1440 105L1440 3.81091e-06L-9.17938e-06 0.0001297Z" fill="white"/></svg>');
            background-size: 101% auto;
            background-repeat: no-repeat;
            background-position: center top;

            .wrapper {
                transform: rotate(-1deg);

                >div {
                    transform: rotate(1deg);
                }
            }

            .content {
                p {
                    font-size: 21px;
                    line-height: 28.35px;
                }
            }

            .floating-icons {
                svg {
                    path {
                        fill: rgba(245, 76, 106, 1);
                    }
                }
            }

            @media (min-width: $breakpoint-sm) {
                padding-top: 7%;
            }
        }

        &-2 {
            background: rgba(244, 244, 244, 1);
            background-size: 101% auto;
            background-repeat: no-repeat;
            background-position: center top;
            background-image: url('data:image/svg+xml,<svg width="1440" height="296" viewBox="0 0 1440 296" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M-2.58771e-05 0.00012207L-1.2732e-05 295.999C371 208.477 1231.5 -52.1507 1440 296L1440 -3.81849e-06L-2.58771e-05 0.00012207Z" fill="%23005CA9"/></svg>');

            .h3,
            p {
                color: rgba(18, 18, 2, 1);
            }

            .floating-icons {
                svg {
                    path {
                        fill: $secondary-color;
                    }
                }

                &:nth-child(1) {
                    top: 0;
                }
            }
        }
    }
}