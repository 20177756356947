@import "styles/mixins";
@import "styles/variables";


.root {
    :global {
        overflow: hidden;

        .swiper-prev {
            @include transform(rotate(-180deg));
            transition: all 0.3s ease;

        }

        .swiper {
            width: 100%;
        }

        button {
            @include trans;

            &:hover,
            &:focus,
            &:active {
                opacity: 0.5;
            }
        }

        .tertiaryBtn {
            margin: 0;
            border: none;
            font-weight: 400;

            &:after {
                background-image: url("/site-assets/svg/search.svg");
                background-repeat: no-repeat;
                height: 17px;
                opacity: 1;
            }
        }

        .floating-icon {
            position: absolute;

            &:nth-child(1) {
                left: -90px;
                top: 120px;
                @include transform(scale(1.4));
            }

            &:nth-child(2) {
                right: 280px;
                top: 20px;
                @include transform(scale(1.4));
            }

            &:nth-child(3) {
                left: 450px;
                top: -30px;
            }

        }

        .wrapper {
            .all-jobs-wrapper {
                gap: 30px;
                @include flex;
                @include flex-wrap(wrap);

                .column {
                    width: 100%;
                }

                &.with-swiper {
                    width: 105vw;

                    .card {
                        z-index: auto;
                        margin: 0 0 1rem 1rem;
                        height: 100%;
                    }

                    .swiper-slide {
                        height: auto;
                    }

                }
            }
        }

        @media (min-width: $breakpoint-lg) {
            .wrapper {
                .all-jobs-wrapper {
                    gap: 60px;

                    .column {
                        width: calc((100%/3) - 40px);
                    }
                }
            }
        }

        @media (min-width: $breakpoint-xxxl) {

            .wrapper {
                .all-jobs-wrapper {
                    &.with-swiper {
                        width: 95vw;

                    }
                }
            }
        }

        @media (max-width: $breakpoint-lg) {
            .wrapper {
                .all-jobs-wrapper {
                    &.with-swiper {
                        width: 180vw;

                    }
                }
            }
        }

    }

    &:global.the-impact-academy {
        background: $base-white;
        padding-top: 3rem;
    }

    &:global.cpd {
        background: rgba(244, 244, 244, 1);

    }

    &:global.homepage {
        background: $primary-color;
        color: $base-white;
        z-index: 2;
        border-bottom: $primary-color;

        button {
            svg {
                path {
                    stroke: $base-white;
                }
            }
        }

        .tertiaryBtn {
            color: $base-white;

            &:after {
                filter: brightness(0) invert(1);

            }
        }
    }

    &:global.teaching-jobs {
        background: $base-white;
        padding-top: 3rem;

        .card {
            background: rgba(247, 247, 247, 1);
            border: 1px solid var(--Cool-Grey, rgba(95, 94, 94, 1));
        }

        @media (min-width: $breakpoint-xxxl) {
            padding-top: 8rem;
        }

    }

    &:global.tradewind-careers {


        .floating-icon {
            display: none;
        }

        @media (max-width: $breakpoint-lg) {
            padding: 0.5rem 0 !important;
        }
    }
}